module.exports = {
  siteTitle: 'Full Potential PLLC', // <title>
  manifestName: 'Full Pontetial',
  manifestShortName: 'FP', // max 12 characters
  manifestStartUrl: '/',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Dr. Claudia Hernandez',
  // social
  socialLinks: [
  ],
};
