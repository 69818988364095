import React from 'react';

import config from '../../config';
const pic = require('../assets/images/avatar.png');

export default function Footer() {
  return (
    <header>
      <span className="avatar">
        <img
          height="128px"
          // width="334px"
          src={pic}
          alt="Full Potential Logo"
         />
      </span>
      <hr/>
      <h1>{config.authorName}</h1>
      <hr/>
      <h2>LICENSED<br/>PSYCHOLOGIST</h2>
      <hr/>
      <p>Diagnostic Evaluations for Children & Adolescents</p>
      <hr/>
      <p>
        FLORIDA KEYS & MIAMI
        <br/>
        (305) 290-8816
        <br/>
        drhernandez@fullpotentialFL.com
        <br/>
        SE HABLA ESPAÑOL
      </p>
    </header>
  );
}
